import(/* webpackMode: "eager" */ "/layers/heroku_nodejs-pnpm-install/virtual/store/next@14.2.21_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/workspace/src/app/(content)/globals.css");
;
import(/* webpackMode: "eager" */ "/layers/heroku_nodejs-pnpm-install/virtual/store/next@14.2.21_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"700\",\"800\"],\"variable\":\"--font-inter\",\"subsets\":[\"cyrillic\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/layers/heroku_nodejs-pnpm-install/virtual/store/next@14.2.21_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"Mulish\",\"arguments\":[{\"weight\":[\"900\"],\"variable\":\"--font-mulish\",\"subsets\":[\"latin\"]}],\"variableName\":\"mulish\"}");
;
import(/* webpackMode: "eager" */ "/layers/heroku_nodejs-pnpm-install/virtual/store/next@14.2.21_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./gilroy-regular.ttf\",\"display\":\"swap\",\"variable\":\"--font-gilroy\"}],\"variableName\":\"gilroy\"}");
;
import(/* webpackMode: "eager" */ "/layers/heroku_nodejs-pnpm-install/virtual/store/next@14.2.21_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./gilroy-bold.ttf\",\"display\":\"swap\",\"variable\":\"--font-gilroy-bold\"}],\"variableName\":\"gilroyBold\"}");
;
import(/* webpackMode: "eager" */ "/layers/heroku_nodejs-pnpm-install/virtual/store/next@14.2.21_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/lib/_fonts/index.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./aa-bebas-neue.ttf\",\"display\":\"swap\",\"variable\":\"--font-bebas\"}],\"variableName\":\"bebasFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/workspace/src/app/(content)/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MenuButton"] */ "/workspace/src/components/(buttons)/menu-button.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/src/components/cookies/cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderNav"] */ "/workspace/src/components/header/header-nav.tsx");
